export const userEP = `http://${process.env.REACT_APP_SERVER}${process.env.REACT_APP_usersEP}`;

export const busEP = `http://${process.env.REACT_APP_SERVER}${process.env.REACT_APP_busEP}`;

export const PerEP = `http://${process.env.REACT_APP_SERVER}${process.env.REACT_APP_PerEP}`;

export const VerifyOTP = `http://${process.env.REACT_APP_SERVER}${process.env.REACT_APP_VerifyOTP}`;

export const ResendOTP = `http://${process.env.REACT_APP_SERVER}${process.env.REACT_APP_ResendOTP}`;

export const LoginUp = `http://${process.env.REACT_APP_SERVER}${process.env.REACT_APP_LoginUp}`;

export const Getallinfo = `http://${process.env.REACT_APP_SERVER}${process.env.REACT_APP_Info}`;

export const SubmitDox = `http://${process.env.REACT_APP_SERVER}${process.env.REACT_APP_Submit}`;

export const SubmitReset = `http://${process.env.REACT_APP_SERVER}${process.env.REACT_APP_Reset_Pass}`;

export const SubmitResetPass = `http://${process.env.REACT_APP_SERVER}${process.env.REACT_APP_Submit_Reset_Pass}`;

export const SubmitLoginOtp = `http://${process.env.REACT_APP_SERVER}${process.env.REACT_APP_LoginOtp}`;
