import { SubmitLoginOtp } from "./Constants";
import axios from "axios";

export async function subloginotp(jsonData) {
  console.log("hello 2");
  try {
    const response = await axios.post(
      SubmitLoginOtp,
      jsonData
    );
    // console.log(response);
    return response;
  } catch (error) {
    return error.response;
  }
}
