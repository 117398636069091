import "./App.css";
import React, {
  useState,
  useEffect,
} from "react";
import LoginPage from "./views/Login/Login";
// import TestPage from "./views/Login/tester";
import Dashboard from "./views/Login/Dashboard";
import MerchantStart from "./views/Login/MerchantStart";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

function App() {
  const [authorized, setAuthorized] =
    useState(null);

  useEffect(() => {
    document.title = "Omari | Business Portal";

    const isDarkMode =
      window.matchMedia &&
      window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;

    if (isDarkMode) {
      console.log("Dark mode is enabled");
      document.documentElement.classList.add(
        "light-theme"
      );
    } else {
      console.log("Dark mode is not enabled");
    }

    const checkIP = async () => {
      try {
        const response = await fetch(
          "http://129.232.245.44:3001/check-ip"
        );

        if (response.ok) {
          const data = await response.json();
          if (data.authorized) {
            setAuthorized(true);
          } else {
            setAuthorized(false); // Unauthorized user
          }
        } else {
          setAuthorized(false); // In case of any other error, mark as unauthorized
        }
      } catch (error) {
        console.error("IP check failed", error);
        setAuthorized(false); // Mark as unauthorized if request fails
      }
    };

    checkIP();
  }, []);

  if (authorized === null) {
    // Show a loading state while waiting for the IP check
    // return <div>Loading...</div>;
  }

  // Do not render anything for unauthorized users
  if (!authorized) {
    return null; // Let the browser handle the timeout error naturally
  }

  // Render the main app for authorized users
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={<LoginPage />}
          />
          <Route
            path="/dashboard"
            element={<Dashboard />}
          />
          <Route
            path="/merchant"
            element={<MerchantStart />}
          />
          {/* <Route path="/test" element={<TestPage />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
